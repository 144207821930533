import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { StouffvilleCommunityHistory } from 'components/StouffvilleCommunityHistory'
import { StouffvilleCommunityGeo } from 'components/StouffvilleCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { StouffvilleCommunityHero } from 'components/TorontoCommunityHero'
import { StouffvilleCommunityInformation } from 'components/StouffvilleCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchStouffville'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import PropertiesComponent from 'components/PropertiesComponentKingCity'
import { FlexDiv } from 'design-system/FlexDiv'
import { Contact } from 'components/Contact'

const KingCityPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
            <FlexDiv className="cityPage">
        <PropertiesComponent defaultCity="KingCity" />
        <Contact />
        </FlexDiv>
    </EllicotLayout>
  )
}

export default KingCityPage
